import Vue from 'vue';
import VueI18n from 'vue-i18n';

import ptBR from './locales/pt-BR.json';
import en from './locales/en.json';

Vue.use(VueI18n);

const defaultLocale = 'pt-BR';
const defaultFallback = 'en';

const languages = {
  'pt-BR': ptBR,
  'en': en
}

const messages = Object.assign(languages);

const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: defaultFallback,
  messages,
});

export default i18n;