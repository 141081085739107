<template>
  <div id="app">
    <header class="fixed-top">
      <Navbar />
    </header>
    <div id="background-image"></div>
    <main id="content">
      <router-view />
    </main>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
export default {
  components: {
    Navbar
  }
}
</script>

<style lang="scss">

#app {
  font-family: "Courier Prime", "Noto Sans", "Open Sans Condensed", sans-serif,
    monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$ws-color-blue: #1FB6FF;
$ws-color-red: #FF1F47;
$ws-color-light: #FFFFFF;
$ws-color-dark: #081A36;

body {
  color: $ws-color-dark
}

::selection {
  background-color: $ws-color-blue;
}

.tinted::selection {
  color: $ws-color-light;
}

.night {
  ::selection {
    background-color: $ws-color-red;
  }
  .tinted::selection {
    color: $ws-color-dark;
  }
}

//  custom scrollbar
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 10px;
  // border: 1px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

@keyframes day {
  from {
    background-color: $ws-color-light;
    color: $ws-color-dark;
  }
  to {
    background-color: $ws-color-dark;
    color: $ws-color-light;
  }
}

@keyframes night {
  from {
    background-color: $ws-color-dark;
    color: $ws-color-light;
  }
  to {
    background-color: $ws-color-light;
    color: $ws-color-dark;
  }
}



.text-emphasis {
  font-size: 4rem;
}
.text-title {
  font-size: 2rem;
}
.text-caption {
  font-size: 1.3rem;
}
.text-small {
  font-size: .8rem;
}

.border {
  border: 1px solid red !important;
}

#content {
  margin-top: 70px;
  min-height: calc(100vh - 70px);
  display: flex;
  align-items: center;
  z-index: 10;
}

@mixin for-size($size) {
  @if $size == xs {
    @media (max-width: 576px) { @content; }
  } @else if $size == sm {
    @media (min-width: 576px) { @content; }
  } @else if $size == md {
    @media (min-width: 768px) { @content; }
  } @else if $size == lg {
    @media (min-width: 992px) { @content; }
  } @else if $size == xl {
    @media (min-width: 1200px) { @content; }
  } @else if $size == xxl {
    @media (min-width: 1400px) { @content; }
  }
}

#background-image {
  position: fixed;
  z-index: -30;
  bottom: 0;
  width: 100%;
  height: 100%;
    
  background-repeat: no-repeat;
  background-attachment: fixed;

  &.background-home {
    background-image: url("./assets/images/backgrounds/home/blue.png");
    background-position: bottom right;

    @include for-size(xs) {
      background-size: 90%;
      opacity: .6;
    }

    @include for-size(sm) {
      background-size: 35%;
      opacity: .6;
    }

    @include for-size(md) {
      background-size: 80%;
    }

    @include for-size(lg) {
      background-size: 55%;
    }

    @include for-size(xl) {
      background-size: 45%;
    }

    @include for-size(xxl) {
      background-size: 55%;
    }

  }

  &.background-about {
    background-image: url("./assets/images/backgrounds/about/blue.png");
    // background-position: center right;

    @include for-size(xs) {
      background-image: none;
      background-position: center center;
      background-size: 100%;
      opacity: .6;
    }

    @include for-size(sm) {
      background-image: none;
      background-position: top right;
      background-size: 80%;
      opacity: .6;
    }

    @include for-size(md) {
      background-image: url("./assets/images/backgrounds/about/blue.png");
      background-position: center right;
      background-size: 60%;
    }

    @include for-size(lg) {
      background-size: 50%;
    }

    @include for-size(xl) {
      background-size: 40%;
    }

    @include for-size(xxl) {
      background-size: 55%;
    }
  }

  &.background-skills {
    background-image: url("./assets/images/backgrounds/skills/blue.png");
    background-position: bottom center;
    background-size: 100%;
  }

  &.background-portfolio {
    background-image: url("./assets/images/backgrounds/portfolio/blue.png");
    background-position: bottom right;
    background-size: 50%;
  }

  &.background-contact {
    background-image: url("./assets/images/backgrounds/contact/blue.png");
    background-position: bottom right;
    background-size: 80%;
    
    @include for-size(xl) {
      background-size: 60%;
    }
  }

}

.night {
  .background-home {
    background-image: url("./assets/images/backgrounds/home/red.png") !important;
  }
  .background-about {
    background-image: url("./assets/images/backgrounds/about/red.png") !important;
  }
  .background-skills {
    background-image: url("./assets/images/backgrounds/skills/red.png") !important;
  }
  .background-portfolio {
    background-image: url("./assets/images/backgrounds/portfolio/red.png") !important;
  }
  .background-contact {
    background-image: url("./assets/images/backgrounds/contact/red.png") !important;
  }
}

.tinted {
  color: $ws-color-blue;
}

@keyframes shake {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(.5rem);
  }
  100% {
    transform: translateY(0);
  }
}

.scroll-info{
  border-bottom: 1px solid;
  animation: shake 1000ms ease-in-out infinite;
}

.skill-items {
  font-size: 2rem;
}

.navbar {
  background-color: $ws-color-light;
  color: $ws-color-dark;

  .navbar-brand {
    color: $ws-color-dark !important;
  }

  .navbar-toggler {
    font-size: 1.5rem;
    color: $ws-color-dark;
  }

  .nav-item {
    color: $ws-color-dark;
    .nav-link {
      font-weight: bold;
      color: $ws-color-dark;

      &.router-link-exact-active {
        color: $ws-color-blue;
      }
    }
  }

}

.btn-settings {
  font-size: 1.5rem;
  color: $ws-color-dark;

  &:hover {
    color: $ws-color-blue;
  }
}

.btn-action {
  background-color: $ws-color-blue;
  color: $ws-color-light;

  &:hover {
    background-color: darken($ws-color-blue, 15%);
    color: $ws-color-light;
  }
}

.day {
  animation: night 200ms ease-in-out;

  .navbar {
    animation: night 200ms ease-in-out;
  }
}

.night {
  animation: day 200ms ease-in-out;
  background-color: $ws-color-dark;
  color: $ws-color-light;

  .tinted {
    color: $ws-color-red;
  }

  .form-floating {
    color: $ws-color-dark !important;
  }

  .navbar {
    animation: day 200ms ease-in-out;
    background-color: $ws-color-dark;
    color: $ws-color-light;

    .navbar-brand {
      color: $ws-color-light !important;
      
      
    }

    .navbar-toggler {
      color: $ws-color-light;
    }

    .nav-item {
      color: $ws-color-light;
      .nav-link {
        color: $ws-color-light;

        &.router-link-exact-active {
          color: $ws-color-red;
        }
      }
    }

  }

  .btn-settings {
    color: $ws-color-light;
    &:hover {
      color: $ws-color-red;
    }
  }
    
  .btn-action {
    background-color: $ws-color-red;
    color: $ws-color-dark;
    &:hover {
      background-color: darken($ws-color-red, 15%);
      color: $ws-color-dark;
    }
  }
}


</style>
