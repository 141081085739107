<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container">
      <router-link to="/" aria-current="page" class="navbar-brand">
        <span class="tinted">&lt;</span>Walmir
        <span class="tinted">/&gt;</span>
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarToggler"
        aria-controls="navbarToggler"
        aria-expanded="false"
        aria-label="Alternar de navegação"
      >
        <i class="las la-bars"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarToggler">
        <ul class="navbar-nav ms-auto me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link to="/" aria-current="page" class="nav-link">
              {{ $t("page.home") }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/about" aria-current="page" class="nav-link">
              {{ $t("page.about") }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/skills" aria-current="page" class="nav-link">
              {{ $t("page.skills") }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/portfolio" aria-current="page" class="nav-link">
              {{ $t("page.portfolio") }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/contact" aria-current="page" class="nav-link">
              {{ $t("page.contact") }}
            </router-link>
          </li>
        </ul>
      </div>
      <button type="button" @click="handleChangeTheme" class="btn btn-settings">
        <i v-if="isDark" class="las la-sun"></i>
        <i v-else class="las la-moon"></i>
      </button>
      <div class="dropdown">
        <button
          type="button"
          class="btn btn-settings dropdown-toggle"
          id="dropdownMenu"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i class="las la-language"></i>
        </button>
        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenu">
          <li>
            <a class="dropdown-item" @click="handleChangeLocale('pt-BR')"
              >Português</a
            >
          </li>
          <li>
            <a class="dropdown-item" @click="handleChangeLocale('en')"
              >Inglês</a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import i18n from "@/i18n/index.js";
export default {
  data() {
    return {
      isDark: false,
      theme: "",
    };
  },
  methods: {
    handleChangeTheme() {
      var body = document.getElementsByTagName("body")[0];
      body.classList.toggle("night");
      let cls = body.className;
      if (cls == "") {
        body.classList.add("day");
      } else {
        body.classList.remove("day");
      }
      this.isDark = !this.isDark;
    },
    handleChangeLocale(locale) {
      i18n.locale = locale;
    },
  },
};
</script>

<style lang="scss">
.navbar-brand {
  font-size: 1.5rem;
}
</style>